<template>
  <div>
    <!-- <c-search-box @enter="getTypeList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="ESG_MANAGEMENT_POINT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="esgManagementPointCd"
            label="지점"
            v-model="searchParam.esgManagementPointCd"
            @datachange="datachange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box> -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          ref="typegrid"
          title="지점 목록"
          tableId="typegrid"
          :columns="typegrid.columns"
          :data="typegrid.data"
          :usePaging="false"
          :hideBottom="false"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          gridHeight="840px"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
          <!-- <template slot="table-button">
            <c-btn label="LBLSEARCH" icon="search"  @btnClicked="getTypeList"/>
          </template> -->
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="itemgrid"
              title="지점별 항목 목록"
              tableId="itemgrid"
              :columns="itemgrid.columns"
              :data="itemgrid.data"
              :usePaging="false"
              :hideBottom="false"
              :columnSetting="false"
              gridHeight="530px"
              selection="multiple"
              rowKey="esgManagementItemId"
            >
              <template slot="table-chip">
                <q-btn-group outline >
                  <q-chip v-if="this.selectedGroup" outline square icon="bookmark">
                    {{"지점: " + this.selectedGroup.codeName }} 
                  </q-chip>
                </q-btn-group>
              </template>
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn label="LBLADD" icon="add" @btnClicked="addItems" v-show="editable && isSelectGrp" />
                  <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow" v-show="editable && isSelectGrp" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="userGrid"
              title="지점별 담당자 목록"
              tableId="userGrid"
              :columns="userGrid.columns"
              :data="userGrid.data"
              :usePaging="false"
              :hideBottom="false"
              :columnSetting="false"
              gridHeight="300px"
              selection="multiple"
              rowKey="userId"
            >
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn label="LBLADD" icon="add" @btnClicked="addUser" v-show="editable && isSelectGrp" />
                  <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeUser" v-show="editable && isSelectGrp" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'esgManagementPoint',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        esgManagementPointCd: null,
        useFlag: 'Y'
      },
      point: {
        results: [],
      },
      typegrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '지점',
            align: 'left',
            sortable: false,
          }
        ],
        data: [],
      },
      itemgrid: {
        columns: [
          {
            name: 'esgManagementItemTypeName',
            field: 'esgManagementItemTypeName',
            label: '분류항목',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
            style: 'width:350px',
          },
          {
            name: 'gmsIndexName',
            field: 'gmsIndexName',
            label: 'GMS Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'sasbIndexName',
            field: 'sasbIndexName',
            label: 'SASB Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
        ],
        data: [],
      },
      userGrid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width:50%',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '담당자명',
            align: 'center',
            sortable: true,
            style: 'width:50%',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSelectGrp: false,
      selectedGroup: '',
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      userListUrl: '',
      userSaveUrl: '',
      userDeleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.esg.point.list.url;
      this.saveUrl = transactionConfig.sai.esg.point.save.url;
      this.deleteUrl = transactionConfig.sai.esg.point.delete.url;
      this.userListUrl = selectConfig.sai.esg.user.list.url;
      this.userSaveUrl = transactionConfig.sai.esg.user.save.url;
      this.userDeleteUrl = transactionConfig.sai.esg.user.delete.url;
      // code setting
      // list setting
      this.getTypeList();
    },
    getTypeList() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.$comm.getComboItems('ESG_MANAGEMENT_POINT_CD', null, 'Y').then(_result => {
        this.typegrid.data = _result;
      });
    },
    rowClick(_row) {
      this.isSelectGrp = true;
      this.selectedGroup = _row
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: this.searchParam.useFlag,
        esgManagementPointCd: _row.code,
      }
      this.$http.request((_result) => {
        this.itemgrid.data = _result.data;
      },
      () => {
      });
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        esgManagementPointCd: _row.code,
      }
      this.$http.request((_result) => {
        this.userGrid.data = _result.data;
      },
      () => {
      });
    },
    removeRow() {
      let selectData = this.$refs['itemgrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.itemgrid.data = this.$_.reject(this.itemgrid.data, item);
              })
              this.$refs['itemgrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addItems() {
      this.popupOptions.title = 'ESG(녹색경영)항목 팝업';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/esg/esgManagementItemPop.vue'}`);
      this.popupOptions.width = '75%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.itemgrid.data) this.itemgrid.data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.itemgrid.data, { esgManagementItemId: item.esgManagementItemId }) === -1) {
            this.itemgrid.data.push({
              esgManagementPointCd: this.selectedGroup.code,
              esgManagementItemId: item.esgManagementItemId,
              esgManagementItemTypeName: item.esgManagementItemTypeName,
              esgManagementItemName: item.esgManagementItemName,
              gmsIndexName: item.gmsIndexName,
              sasbIndexName: item.sasbIndexName,
              esgManagementItemUnitName: item.esgManagementItemUnitName,
              sortOrder: item.sortOrder,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
          this.$http.url = this.saveUrl;
          this.$http.type = 'POST';
          this.$http.param = this.itemgrid.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(this.selectedGroup);
          });
        })
      }
    },
    datachange() {
      this.itemgrid.data = [];
      this.rowRemoveSelect();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    addUser() {
      this.popupOptions.title = 'ESG(녹색경영)담당자 팝업';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.userGrid.data) this.userGrid.data = [];
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.userGrid.data, { userId: _item.userId }) === -1) {
            this.userGrid.data.push({
              esgManagementPointCd: this.selectedGroup.code,
              userId: _item.userId,
              userName: _item.userName,
              deptCd: _item.deptCd,
              deptName: _item.deptName,
            })
          }
        })
        this.$http.url = this.userSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = this.userGrid.data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.rowClick(this.selectedGroup);
        });
      }
    },
    removeUser() {
      let selectData = this.$refs['userGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.userDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.userGrid.data = this.$_.reject(this.userGrid.data, item);
              })
              this.$refs['userGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
